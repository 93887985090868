// src/providers/authProvider.ts
import { AuthProvider } from 'react-admin'
import axios from 'axios'

const API_URL = 'https://api-v2.icommunify.com'

const authProvider: AuthProvider = {
  // Login method to handle authentication
  login: async ({ username, password }) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/sessions`, {
        _type: 'SessionCreate',
        email: username,
        password
      })

      const { token, refreshToken, sessionId } = response.data

      // Store tokens and sessionId in localStorage
      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('sessionId', sessionId)

      // Set the authorization header for future requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // Logout method to handle deauthentication
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('sessionId')
    delete axios.defaults.headers.common['Authorization']
    return Promise.resolve()
  },

  // Method to check if user is authenticated
  checkAuth: () => {
    const token = localStorage.getItem('token')
    if (token) {
      return Promise.resolve()
    } else {
      return authProvider.refreshSession() // Try refreshing the session if the token is missing
    }
  },

  // Refresh session method to handle session refresh
  refreshSession: async () => {
    try {
      const sessionId = localStorage.getItem('sessionId')
      const refreshToken = localStorage.getItem('refreshToken')

      if (!sessionId || !refreshToken) {
        return Promise.reject()
      }

      const response = await axios.patch(`${API_URL}/api/auth/sessions/${sessionId}/refresh`, {
        _type: 'SessionRefresh',
        refreshToken
      })

      const { token, newRefreshToken } = response.data

      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', newRefreshToken)

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      return Promise.resolve()
    } catch (error) {
      //@ts-ignore
      authProvider.logout()
      return Promise.reject(error)
    }
  },

  // Method to check errors in API responses
  checkError: error => {
    const status = error.status
    if (status === 401 || status === 403) {
      return authProvider.refreshSession()
    }
    return Promise.resolve()
  },

  // Method to get permissions, which we don't use here
  getPermissions: () => Promise.resolve()
}

export default authProvider
