import React from 'react'
import { Admin, Resource } from 'react-admin'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

// Import Icons
import PeopleIcon from '@mui/icons-material/People'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import GroupsIcon from '@mui/icons-material/Groups'
import SchoolIcon from '@mui/icons-material/School'
import EventIcon from '@mui/icons-material/Event'

import UserList from './resources/users/UserList'
import { UserEdit } from './resources/users/UserEdit'
import { UserCreate } from './resources/users/UserCreate'
import { UserShow } from './resources/users/UserShow'

import ClubsList from './resources/clubs/ClubList'
import ClubCreate from './resources/clubs/ClubCreate'
import ClubEdit from './resources/clubs/ClubEdit'
import ClubShow from './resources/clubs/ClubShow'

import UniversityList from './resources/universities/UniversityList'
import UniversityCreate from './resources/universities/UniversityCreate'
import UniversityEdit from './resources/universities/UniversityEdit'
import UniversityShow from './resources/universities/UniversityShow'

import EventList from './resources/events/EventList'
import EventShow from './resources/events/EventShow'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2'
    },
    secondary: {
      main: '#dc004e'
    }
  }
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Admin authProvider={authProvider} dataProvider={dataProvider}>
        <Resource name='profiles' list={UserList} icon={AccountCircleIcon} />
        <Resource
          name='users'
          edit={UserEdit}
          create={UserCreate}
          show={UserShow}
          icon={PeopleIcon}
        />
        <Resource
          name='clubs'
          list={ClubsList}
          edit={ClubEdit}
          create={ClubCreate}
          show={ClubShow}
          icon={GroupsIcon}
        />
        <Resource
          name='universities'
          list={UniversityList}
          edit={UniversityEdit}
          create={UniversityCreate}
          show={UniversityShow}
          icon={SchoolIcon}
        />
        <Resource name='events' list={EventList} show={EventShow} icon={EventIcon} />
      </Admin>
    </ThemeProvider>
  )
}

export default App
