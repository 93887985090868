import React, { useState, useEffect } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  minLength,
  maxLength
} from 'react-admin'
import { State } from 'country-state-city'

const UniversityEdit: React.FC = props => {
  const [states, setStates] = useState<{ id: string; name: string }[]>([])

  useEffect(() => {
    const usStates = State.getStatesOfCountry('US').map(state => ({
      id: state.isoCode,
      name: state.name
    }))
    setStates(usStates)
  }, [])

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          label='University Name'
          fullWidth
          validate={[required(), minLength(1), maxLength(300)]}
        />

        <SelectInput
          source='status'
          label='Status'
          choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' }
          ]}
        />

        <TextInput source='description' label='Description' fullWidth multiline />
        <TextInput source='address' label='Address' fullWidth />

        <SelectInput
          source='stateCode'
          label='State'
          choices={states}
          optionText='name'
          optionValue='id'
        />

        <TextInput source='website' label='Website' fullWidth />
        <TextInput source='email' label='Contact Email' fullWidth />

        <ArrayInput source='emailDomains' label='Email Domains'>
          <SimpleFormIterator>
            {/* 
            // @ts-ignore */}
            <TextInput label='Domain' validate={[required()]} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default UniversityEdit
