import React, { useState, useEffect } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin'
import { State } from 'country-state-city'

const UniversityCreate = () => {
  const [states, setStates] = useState([])

  useEffect(() => {
    const usStates = State.getStatesOfCountry('US').map(state => ({
      id: state.isoCode,
      name: state.name
    }))
    setStates(usStates)
  }, [])

  return (
    <Create>
      <SimpleForm>
        <TextInput source='name' label='Name' />
        <SelectInput
          source='status'
          label='Status'
          choices={[
            { id: 'Active', name: 'Active' },
            { id: 'Pending', name: 'Pending' }
          ]}
        />
        <TextInput source='description' label='Description' />
        <TextInput source='address' label='Address' />
        <TextInput source='website' label='Website' />
        <TextInput source='email' label='Contact Email' />

        <ArrayInput source='emailDomains' label='Email Domains'>
          <SimpleFormIterator>
            {/* 
            // @ts-ignore */}
            <TextInput label='Domain' />
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput source='countryCode' label='Country Code' defaultValue='US' disabled />
        <SelectInput
          source='stateCode'
          label='State'
          choices={states}
          optionText='name'
          optionValue='id'
        />
      </SimpleForm>
    </Create>
  )
}

export default UniversityCreate
